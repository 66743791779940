<template lang="pug">
  div
</template>

<script type="text/javascript">
import lioshutanApi from '@/api';

export default {
  name: 'SystemTestingUrl',

  created() {
    this.toTestPage();
  },

  methods: {
    async toTestPage(){
      const response = await lioshutanApi.classroom.getSystemTestingUrl();
      const url = response.data.data.self_test_platform_url;
      window.location.href = url;
    },
  },
};
</script>
